form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

p {
    margin-bottom: 2px;
}

input {
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 4px;
}

button {
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 4px 8px;
}

h1 {
    text-align: center;
}