.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.connect-button-wrapper {
  display: flex;
  flex-flow: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(49, 28, 49);
  border-radius: 9999px;
  border: none;
  padding: 0px;
  height: 40px;
  color: rgb(252, 114, 255);
  transition: color 125ms ease-in 0s;
}

.connect-button {
  background-color: transparent;
  border: none;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  cursor: pointer;
  font-weight: 535;
  font-size: 16px;
  padding: 10px 12px;
  color: inherit;
  user-select: none;
}

#page-topbar {
  left: 0 !important;
}